export default (type) => {
  switch (type) {
    case 'File Upload':
      return 'paper-clip'
    case 'Essay':
      return 'align-left'
    case 'Multiple Choice':
      return 'unordered-list'
    default:
      return 'minus-circle'
  }
}
